<script lang="ts" setup>
import { ref } from "vue";
import type { UnitLength } from "~/types/onboarding/registrationParams";

interface Props {
  cm: string | null;
  feet: string | null;
  inch: string | null;
  unitLength: UnitLength;
  cmInvalid?: boolean;
  ftInvalid?: boolean;
  inInvalid?: boolean;
  errorMessage?: string;
  disableAutoFocus?: boolean;
}

const props = defineProps<Props>();

interface Emits {
  (e: "update:cm", value: string | null): void;
  (e: "update:feet", value: string | null): void;
  (e: "update:inch", value: string | null): void;
  (e: "update:unitLength", value: UnitLength): void;
  (e: "submit"): void;
  (e: "blur"): void;
}

const emit = defineEmits<Emits>();

function isUnitSelected(unit: UnitLength) {
  return props.unitLength === unit;
}

const cmInput = ref<HTMLInputElement>();
const feetInput = ref<HTMLInputElement>();

function setFocus() {
  void nextTick(() => {
    if (props.unitLength === "cm") {
      if (cmInput.value) {
        cmInput.value.focus();
      }
    } else if (props.unitLength === "in") {
      if (feetInput.value) {
        feetInput.value.focus();
      }
    }
  });
}

onMounted(() => {
  if (!props.disableAutoFocus) {
    setFocus();
  }
});
watch(() => props.unitLength, setFocus);

function submitOnEnter(event: KeyboardEvent) {
  if (event.key === "Enter") {
    emit("submit");
  }
}
</script>

<template>
  <div>
    <div class="row g-3 !tw-justify-center">
      <div v-if="isUnitSelected('cm')" class="col-6">
        <div class="input-group">
          <input
            id="heightInput"
            ref="cmInput"
            inputmode="numeric"
            :value="cm"
            class="form-control form-control-lg"
            :class="{ 'is-invalid-no-icon': cmInvalid }"
            type="number"
            :autofocus="!disableAutoFocus"
            @input="$emit('update:cm', ($event.target as HTMLInputElement).value)"
            @keydown="submitOnEnter"
            @blur="emit('blur')"
          />
          <span class="input-group-text">{{ $t("registration.unit.cm") }}</span>
        </div>
      </div>
      <template v-else>
        <div class="col-sm-4 col-6">
          <div class="input-group">
            <input
              ref="feetInput"
              inputmode="numeric"
              :autofocus="!disableAutoFocus"
              :value="feet"
              class="form-control form-control-lg"
              :class="{ 'is-invalid-no-icon': ftInvalid }"
              type="number"
              @input="$emit('update:feet', ($event.target as HTMLInputElement).value)"
              @keydown="submitOnEnter"
              @blur="emit('blur')"
            />
            <span class="input-group-text">{{ $t("registration.unit.ft") }}</span>
          </div>
        </div>
        <div class="col-sm-4 col-6">
          <div class="input-group">
            <input
              :value="inch"
              inputmode="numeric"
              class="form-control form-control-lg"
              :class="{ 'is-invalid-no-icon': inInvalid }"
              type="number"
              @input="$emit('update:inch', ($event.target as HTMLInputElement).value)"
              @keydown="submitOnEnter"
              @blur="emit('blur')"
            />
            <span class="input-group-text">{{ $t("registration.unit.in") }}</span>
          </div>
        </div>
      </template>
    </div>
    <div class="mt-3 !tw-text-center">
      <button
        id="unitCm"
        class="btn btn-pill"
        :class="{ active: isUnitSelected('cm') }"
        @click="emit('update:unitLength', 'cm')"
      >
        {{ $t("registration.unit.cm") }}
      </button>
      <button
        id="unitFtIn"
        class="btn btn-pill ms-3"
        :class="{ active: isUnitSelected('in') }"
        @click="emit('update:unitLength', 'in')"
      >
        {{ $t("registration.unit.ft_in") }}
      </button>
    </div>
    <div class="mt-3">
      <div class="text-danger !tw-w-full !tw-text-center">&nbsp;{{ errorMessage }}</div>
    </div>
  </div>
</template>
