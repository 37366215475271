<script lang="ts" setup>
import { ref } from "vue";
import type { UnitMass } from "~/types/onboarding/registrationParams";

interface Props {
  kg: string | null;
  lb: string | null;
  unitMass: UnitMass;
  kgInvalid?: boolean;
  lbInvalid?: boolean;
  errorMessage?: string;
  disableAutoFocus?: boolean;
}
const props = defineProps<Props>();

interface Emits {
  (e: "update:kg", value: string | null): void;
  (e: "update:lb", value: string | null): void;
  (e: "update:unitMass", value: UnitMass): void;
  (e: "submit"): void;
  (e: "blur"): void;
}
const emit = defineEmits<Emits>();

function isUnitSelected(unit: UnitMass) {
  return props.unitMass === unit;
}

function updateWeight(val: string) {
  if (props.unitMass === "kg") {
    emit("update:kg", val);
  } else {
    emit("update:lb", val);
  }
}

const numberInput = ref<HTMLInputElement>();

function setFocus() {
  void nextTick(() => {
    if (numberInput.value) {
      numberInput.value.focus();
    }
  });
}
onMounted(() => {
  if (!props.disableAutoFocus) {
    setFocus();
  }
});

watch(() => props.unitMass, setFocus);

function submitOnEnter(event: KeyboardEvent) {
  if (event.key === "Enter") {
    emit("submit");
  }
}
</script>

<template>
  <div>
    <div class="row g-3 !tw-justify-center">
      <div class="col-6">
        <div class="input-group">
          <input
            id="weightInput"
            ref="numberInput"
            inputmode="decimal"
            :autofocus="!disableAutoFocus"
            :value="isUnitSelected('kg') ? kg : lb"
            class="form-control form-control-lg"
            :class="{ 'is-invalid-no-icon': isUnitSelected('kg') ? kgInvalid : lbInvalid }"
            type="number"
            @paste.prevent
            @input="updateWeight(($event.target as HTMLInputElement).value)"
            @keydown="submitOnEnter"
            @blur="emit('blur')"
          />
          <span class="input-group-text">
            {{ isUnitSelected("kg") ? $t("registration.unit.kg") : $t("registration.unit.lb") }}
          </span>
        </div>
      </div>
    </div>
    <div class="mt-3 !tw-text-center">
      <button
        id="unitKg"
        class="btn btn-pill"
        :class="{ active: isUnitSelected('kg') }"
        @click="$emit('update:unitMass', 'kg')"
      >
        {{ $t("registration.unit.kg") }}
      </button>
      <button
        id="unitLb"
        class="btn btn-pill ms-3"
        :class="{ active: isUnitSelected('lb') }"
        @click="$emit('update:unitMass', 'lb')"
      >
        {{ $t("registration.unit.lb") }}
      </button>
    </div>
    <div class="mt-3">
      <div class="text-danger !tw-w-full !tw-text-center">&nbsp;{{ errorMessage }}</div>
    </div>
  </div>
</template>
