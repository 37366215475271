<script lang="ts" setup>
import { useMounted } from "@vueuse/core";
import AppIcon from "~/components/app/AppIcon.vue";

interface Props {
  emoji?: string;
  icon?: string;
  image?: string;
  translationKey: string;
  descriptionTranslationKey?: string;
  isColumn?: boolean;
  fullWidth?: boolean;
  centerText?: boolean;
}

withDefaults(defineProps<Props>(), {
  fullWidth: true,
  centerText: false,
  emoji: "",
  icon: "",
  image: "",
  descriptionTranslationKey: "",
});

const isMounted = useMounted();
</script>

<template>
  <button
    class="btn btn-card btn-xl !tw-flex !tw-items-center !tw-text-left"
    :class="{
      '!tw-flex-col': isColumn,
      'placeholder placeholder-wave': !isMounted,
      '!tw-w-full': fullWidth,
      'card-image': image,
      'gap-2': !image,
    }"
    :disabled="!isMounted"
  >
    <AppIcon v-if="icon" :icon="icon" :class="isColumn ? '!tw-mb-2' : 'me-2'" />
    <span v-else-if="image" :style="`background-image: url(${image})`" class="image" alt=""></span>
    <span v-else class="emoji" :class="{ '!tw-mb-2': isColumn, 'me-2': !isColumn, 'display-1': isColumn }">
      {{ emoji }}
    </span>
    <div
      class="text"
      :class="{
        'tw-flex tw-flex-grow tw-items-center tw-text-sm': centerText,
      }"
    >
      <p class="tw-m-0 !tw-min-w-20 !tw-text-center">
        {{ $t(translationKey) }}
      </p>
      <span v-if="descriptionTranslationKey" class="text-secondary text-body-sm !tw-mb-0 !tw-flex">
        {{ $t(descriptionTranslationKey) }}
      </span>
    </div>
    <slot />
  </button>
</template>

<style scoped lang="scss">
.card-image {
  padding: 0;
  border: 0;
  min-width: 9rem;
  gap: 0;
  flex-grow: 1;
  flex-basis: min-content;

  @media (min-width: 576px) {
    min-width: 15rem;
  }

  .text {
    padding: 1rem;
  }

  .image {
    width: 100%;
    height: 6rem;
    background-size: cover;
    background-position: top center;
    border-radius: 0.75rem 0.75rem 0 0;

    @media (min-width: 576px) {
      height: 8rem;
    }
  }
}
</style>
